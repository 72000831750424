<template>
  <div class="index">
    <div class="header">
      <h2 style="color:var( --td-text-color-primary)">打卡管理</h2>
      <t-button theme="primary" @click="add_toast=true,venue_toast_title='添加打卡动态',this.initForm()">
        <template #icon>
          <t-icon name="add"></t-icon>
        </template>
        添加
      </t-button>
    </div>
    <div class="table">
      <t-table :fixed-rows="[0,0]" :loading="table_loading" :data="data" :columns="columns" row-key="id" table-layout="fixed" style="height: calc(100% - 90px);border-bottom: 1px solid var(--td-border-level-2-color);overflow-y:auto" headerAffixedTop>
        <template #img="{ col, row }">
          <el-image
              style="width: 70px;height: 70px;"
              :src="row.img.split('|')[0]"
              :preview-src-list="row.img.split('|')"
              :initial-index="4"
              fit="cover"
          />
        </template>
        <template #state="{ col, row }">
          <t-button variant="text" :theme="state(row,true)"> {{ state(row) }} </t-button>
        </template>
        <template #opertion="{row}">
          <t-popconfirm content="确认删除该动态？" theme="danger" @confirm="del_venue(row)">
            <t-button variant="text" theme="danger"> 删除 </t-button>
          </t-popconfirm>
          <t-button size="medium" theme="success" @click="examine(row)" :disabled="row.state!=='0'"> 审核 </t-button>
        </template>
      </t-table>
      <div class="pages">
        <t-pagination
            v-model="pages"
            :pageSize="limit"
            :total="total"
            show-jumper
            style="width: 100%;"
            @currentChange="pageChange"
            @page-size-change="pageSizeChange"
        />
      </div>
    </div>
    <t-dialog :header="venue_toast_title" placement="center" :visible="add_toast" @confirm="up" @close="add_toast=false" width="800px">
      <template #body>
        <t-form :data="formData" :rules="rules" ref="form">
          <t-form-item label="用户名" name="nickname" v-show="venue_toast_title==='审核打卡'">
            <t-input placeholder="请输入内容" v-model="formData.nickname" :disabled="venue_toast_title==='审核打卡'"/>
          </t-form-item>
          <t-form-item label="打卡展馆" name="name">
            <t-select @change="choice_venue" v-model="formData.name" :options="name_list" placeholder="请选择展馆" :disabled="venue_toast_title==='审核打卡'"/>
          </t-form-item>
          <t-form-item label="打卡内容" name="con">
            <t-textarea placeholder="请输入内容"  v-model="formData.con" :disabled="venue_toast_title==='审核打卡'"/>
          </t-form-item>
          <t-form-item label="图片或视频" name="address_str">
            <div v-if="venue_toast_title!=='审核打卡'" style="width:100%;display: flex;justify-content: flex-start;align-content: flex-start;flex-direction: column">
              <div style="display:flex;margin-bottom: 20px">
                <el-upload
                    action="https://gqt.yltest.top/file"
                    :http-request="up_img"
                    list-type="picture-card"
                    :on-remove="del_img"
                    :file-list="file1">
                  <icon name="add" size="50"></icon>
                </el-upload>
              </div>
              <el-progress :percentage="progress"></el-progress>
            </div>
            <div v-else>
              <template v-for="item in examine_img">
                <el-image
                    v-if="/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(item)"
                    style="width: 200px; height: 200px;margin: 0 10px;"
                    :src="item"
                    :preview-src-list="is_only_img"
                    :initial-index="0"
                    :z-index="2502"
                    preview-teleported
                    fit="cover"/>
                <video style="max-width: 200px;height: 200px;margin: 0 10px;" v-else :src="item" controls></video>
              </template>
            </div>
          </t-form-item>
          <t-form-item label="审核状态" name="state" v-show="venue_toast_title==='审核打卡'">
            <t-input placeholder="请输入内容" :value='state(formData)' :disabled="venue_toast_title==='审核打卡'" />
          </t-form-item>
        </t-form>
      </template>
      <template #footer v-if="venue_toast_title==='审核打卡'">
        <t-button theme="default" @click="add_toast=false">取消</t-button>
        <t-button theme="danger" @click="set_state('2')"> 不通过 </t-button>
        <t-button theme="primary" @click="set_state('1')"> 通过 </t-button>
      </template>
    </t-dialog>
  </div>
</template>
<script>
import {add_venue, del_venue, edit_venue, get_address_poin, get_venue_id, get_venue_list} from "../../api/venue";
import { shallowRef } from '@vue/reactivity'
import {Icon} from 'tdesign-icons-vue-next'
import AMapLoader from '@amap/amap-jsapi-loader';
import {MessagePlugin} from "tdesign-vue-next";
import { ElUpload,ElImage,ElProgress } from 'element-plus'
import 'element-plus/dist/index.css'
import {
  get_venue_comment_list,
  add_venue_comment,
  get_all_venue_name,
  del_venue_comment,
  set_state
} from "../../api/comment";
import {get_progress, up} from "../../utils/tx_cos_up";

export default {
  components:{
    Icon,
    ElUpload,
    ElImage,
    ElProgress
  },
  setup(){
    const map = shallowRef(null);
    return{
      map,
    }
  },
  data(){
    return {
      search_address_loading:false,
      current:1,
      data:[],
      pages:1,
      limit:10,
      total:0,
      table_loading:false,
      name_list:[],
      columns:[
        {
          colKey: 'nickname',
          title: '用户名',
        },
        {
          colKey: 'name',
          title: '打卡展馆',
        },
        {
          colKey: 'con',
          title:'打卡内容',
          ellipsis:true,
        },
        {
          colKey: 'img',
          title:'图片',
        },
        {
          colKey: 'state',
          title:'审核状态',
        },
        {
          colKey: 'opertion',
          title:'操作',
          align:'center',
          width:'250px',
          className: 'row_tr',
        }
      ],
      add_toast:false,
      formData:{
        name:'',
        con:"",
        img:''
      },
      venue_id:'',
      choice_time:[],
      choice_poin_toast:false,
      address:'',
      markers:[],
      file1:[],
      rules:{
        name:[{ required: true}],
        con:[{ required: true}],
        img:[{ required: true}]
      },
      venue_toast_title:'添加动态',
      progress:0,
      progress_time:null
    }
  },
  computed:{
    state(){
      return ({state},color=false)=>{
        switch (state){
          case '0':
            if (!color)return '待审核'
              else return 'primary'
            break
          case '1':
            if (!color)return '通过'
            else return 'success'
            break
          case '2':
            if (!color)return '不通过'
            else return 'danger'
            break
        }
      }
    },
    examine_img(){
      console.log(this.formData.img.split('|'))
      return this.formData.img.split('|')
    },
    is_only_img(){
      let img = this.formData.img.split('|').filter(item=>{
        if(/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(item)){
          return item
        }
      })
      return img
    }
  },
  mounted() {
    this.get_list()
    //获取展馆列表
    this.get_venue_list()
  },
  methods:{
    //上传图片
    async up_img(e){
      let x = 0
      setInterval(()=>{
        this.progress = Number(get_progress())
        if(this.progress===100 || x===1){
          this.progress_time = null
          clearInterval(this.progress_time)
        }
      })
      let res = await up((e.file.uid.toString()+e.file.name),e.file)
      x = 1
      if(this.formData.img===''){
        this.formData.img += 'https://'+res
      }else {
        this.formData.img += '|'+'https://'+res
      }
      console.log(this.formData.img)
    },
    async set_state(state){
      let {code,msg} = await set_state({id:this.formData.id,state})
      if(code===100)MessagePlugin.success(msg)
      else MessagePlugin.error(msg)
      this.add_toast=false
      this.data = []
      this.get_list()
    },
    choice_venue(e){
      this.venue_id = e
    },
    async get_venue_list(){
      let {data} = await get_all_venue_name()
      data.forEach(item=>{
        this.name_list.push({
          label:item.name,
          value:item.id
        })
      })
    },
    //初始化地图
    initMap(){
      this.choice_poin_toast = true
      AMapLoader.load({
        key:"1c9d6f1f79687710334ee12f44b89411",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:[''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.map = new AMap.Map("container",{  //设置地图容器id
          viewMode:"3D",    //是否为3D地图模式
          zoom:5,           //初始化地图级别
          center:[105.602725,37.076636], //初始化地图中心点位置
        });
        this.map.on('click',(e)=>this.user_set_poin(e))
      }).catch(e=>{
        console.log(e);
      })
    },
    async get_list(){
      this.table_loading = true
      let res = await get_venue_comment_list({pages:this.pages,limit:this.limit})
      this.data = [...res.data,...this.data]
      this.table_loading = false
      console.log(this.data)
      this.total = res.count
    },
    pageChange(e){
      this.pages = e
      this.data = []
      this.get_list()
    },
    pageSizeChange(e){
      this.limit = e
      this.data = []
      this.get_list()
    },
    timeChange(e){
      this.formData.work_time.start = e[0]
      this.formData.work_time.end = e[1]
    },
    //设置坐标
    choice_poin(){
      this.choice_poin_toast = false
      console.log('sdafasdf')
    },
    async search_address(){
      if(this.address===''){
        MessagePlugin.warning({content:'请输入详细地址'})
        return
      }
      this.search_address_loading = true
      let data = await get_address_poin({address:this.address})
      this.search_address_loading = false
      this.set_poin(data.data.geocodes[0].location.split(','))
      console.log(data)
    },
    set_poin(poin){
      this.removeMarkers()
      this.formData.address_poin.lat = poin[0]
      this.formData.address_poin.lng = poin[1]
      var marker = new AMap.Marker({
        position: new AMap.LngLat(poin[0], poin[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter(poin);
      this.map.setZoom(16);
    },
    //用户手动设置定位点
    user_set_poin(e){
      this.removeMarkers()
      this.formData.address_poin.lat = e.lnglat.lng
      this.formData.address_poin.lng = e.lnglat.lat
      var marker = new AMap.Marker({
        position: new AMap.LngLat(e.lnglat.lng,e.lnglat.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter([e.lnglat.lng,e.lnglat.lat]);
    },
    removeMarkers(){
      for(let i of this.markers){
        this.map.remove(i)
      }
      this.markers = []
    },
    upImg(e){
      console.log(e)
      if(this.formData.img===''){
        this.formData.img += e.data
      }else {
        this.formData.img += '|'+e.data
      }
      console.log(this.formData.img)
    },
    del_img(e){
      console.log(e)
      let img = ''
      this.formData.img.split('|').forEach((item,index)=>{
        if(item !== e.url){
          if(img===''){
            img += item
          }else{
            img += '|'+item
          }
        }
      })
      this.formData.img = img
      console.log(this.file1)
      console.log(this.formData.img)
    },
    initForm(){
      this.formData = {
        name:'',
        con:"",
        img:''
      }
      this.choice_time = []
      this.file1 = []
      this.$refs.form.clearValidate()
    },
    async up(){
      if(await this.$refs.form.validate()!==true){
        MessagePlugin.warning({content:'请填写完整'})
        return
      }
      this.$loading(true);
      let data = await add_venue_comment({con:this.formData.con,venue_id:this.venue_id,img:this.formData.img})
      this.$loading(false)
      if(data.code===100){
        MessagePlugin.success({content:"添加成功！"})
        this.add_toast = false
      }else {
        MessagePlugin.error({content:"添加失败！"})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    async edit_up(){
      this.$loading(true);
      let id = this.formData.id
      delete this.formData.id
      let data = await edit_venue({id,up:this.formData})
      this.$loading(false)
      if(data.code===100){
        MessagePlugin.success({content:"编辑成功！"})
        this.add_toast = false
      }else {
        MessagePlugin.error({content:"编辑失败！"})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    //  删除展馆
    async del_venue({id}){
      let {code,msg} = await del_venue_comment({id})
      if(code===100) MessagePlugin.success(msg)
      else MessagePlugin.error(msg)
      this.data = []
      this.get_list()
    },
    //  审核
    async examine(row){
      this.venue_toast_title = '审核打卡'
      this.formData = row
      this.file1 = []
      this.formData.img.split('|').map(item=>{
        this.file1.push({
          lastModified:123045,
          name:'sdaf',
          percent:100,
          raw:{},
          response:{},
          size:10,
          status:'success',
          type:'image',
          url:item
        })
      })
      this.add_toast = true
    }
  }
}
</script>

<style scoped>
#container{
  width: 100%;
  height: 600px;
}
.table{
  height: calc(100% - 90px);
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}
.index{
  width: 100%;
  height: 100%;
  background-color: var(--td-bg-color-container);
}
.row_tr{
  height: 40px;
}
.header>h2{
  font-size: 24px;
  color: #383838;
  letter-spacing: 1px;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 8px 12px;
  box-sizing: border-box;
}
.pages{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 0 8px;
  box-sizing: border-box;
}
</style>

<style>

.index .t-time-picker{
  width: 100%;
}
.index .t-pagination__total{
  text-align: left;
}
.index .t-button--variant-base.t-button--theme-primary{
  background-color: #42b983;
  border-color: #42b983;
}
.index .t-button--variant-base.t-button--theme-primary>div>div{
  background-color: #24b07d !important;
}
.index>.t-table__header>tr{
  background-color: #eee;
}
</style>
