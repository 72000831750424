import axios from '../utils/request'

export function get_venue_comment_list(params){
    return axios({
        url:'/get_venue_comment_list',
        method:'get',
        params
    })
}

export function add_venue_comment(data){
    return axios({
        url:'/add_venue_comment',
        method:'post',
        data
    })
}

export function get_all_venue_name(){
    return axios({
        url:'/get_all_venue_name',
        method:'get'
    })
}

export function del_venue_comment(data){
    return axios({
        url:'/del_venue_comment',
        method:'post',
        data
    })
}

export function set_state(data){
    return axios({
        url:'/set_state',
        method:'post',
        data
    })
}
